import React from 'react';

const StadiumGoodsIcon = () => {
  return (
    <svg viewBox="0 0 158 16" xmlns="http://www.w3.org/2000/svg">
      <path d="m8.5029 6.1662-0.92686-0.28212c-0.64477-0.20151-1.6119-0.56423-1.491-1.4106 0.12089-0.80604 1.1284-1.2494 1.8537-1.2494 1.1686-0.0403 2.0955 0.16121 2.7 0.72544l0.6448-3.0227c-1.0881-0.64484-2.2567-0.92695-3.3851-0.92695-2.9821 0-5.4 1.9748-5.8835 4.9572-0.44328 2.8615 1.0478 3.5063 3.3447 4.1914 0.76567 0.24182 2.2567 0.60454 2.0552 1.6927-0.16119 1.0479-1.2089 1.4912-2.0955 1.4912-1.3298 0-2.3373-0.6851-3.1836-1.5315l-2.1358 3.1033c1.3298 1.0478 3.0627 1.6927 4.9164 1.6927 1.491 0 3.103-0.403 4.4328-1.4106 1.3298-1.0076 1.9343-2.4987 2.1761-3.9899 0.3627-2.3779-1.0478-3.4257-3.0224-4.0302z"></path>
      <path d="m43.401 3.4258c-1.4507-2.8212-4.4328-2.8212-6.1253-2.8212h-4.997l-2.2164 11.889-2.4984-11.889h-3.0224l-7.8984 13.461 1.8537-10.076h3.0223l1.9344-3.3854h-11.243l-0.6448 3.3854h3.103l-2.1761 11.688h7.3342l1.209-2.1764h5.2387l0.3224 2.1764h8.1805c5.7223 0 8.382-3.466 9.1477-7.5769 0.2821-1.6121 0.2418-3.2242-0.5239-4.6751zm-20.754 7.0529 2.4985-5.1587h0.0806l0.6044 5.1587h-3.1835zm17.409-2.2973c-0.403 2.1361-1.5717 4.1512-4.4328 4.1512h-1.6522l1.5313-8.3023h1.6522c1.6925 0 2.3373 0.72544 2.6597 1.3703 0.403 0.84635 0.403 1.9345 0.2418 2.7809z"></path>
      <path d="m45.778 0.64453-2.8209 15.033h3.8284l2.8208-15.033h-3.8283z"></path>
      <path d="m61.938 0.64453h-3.8686l-1.6925 9.1083c-0.2418 1.2091-0.5239 2.8615-2.4582 2.8615-1.894 0-1.6119-1.8539-1.4104-2.8615l1.6925-9.1083h-3.8283l-1.6926 9.1083c-0.4029 2.2167-0.3626 3.2645 0.6448 4.6348 1.0075 1.33 2.9418 1.6121 4.0298 1.6121 1.8537 0 3.3448-0.6448 4.5134-1.6121 1.1284-0.9672 1.894-2.0957 2.3776-4.6348l1.6925-9.1083z"></path>
      <path d="m68.748 10.559h-0.0403l-1.1686-9.9144h-4.9164l-2.8209 15.033h3.5463l1.9746-11.244 0.0403-0.0403 1.1283 11.285h2.5388l5.3194-11.285 0.0403 0.0403-2.2164 11.244h3.5462l2.7806-15.033h-4.9567l-4.7955 9.9144z"></path>
      <path d="m144.51 6.6096-0.927-0.28211c-0.645-0.20152-1.612-0.56424-1.491-1.4106 0.121-0.80605 1.128-1.2494 1.853-1.2494 0.927 0 1.773 0.40302 2.378 0.96725l2.015-2.9824c-1.209-0.76574-2.942-1.2091-4.433-1.2091-2.498 0-4.634 1.4106-5.521 3.6675-0.08-0.20151-0.161-0.36272-0.242-0.56423-1.41-2.8212-4.432-2.8212-6.125-2.8212h-4.997l-0.725 3.869c-0.846-2.4181-3.103-4.1511-6.287-4.1511-4.07 0-6.85 2.6196-8.1 4.5542-0.685-2.7002-3.022-4.6751-6.447-4.6751-4.232 0-7.0928 2.8212-8.2212 4.796-0.3224 0.56423-0.6044 1.1285-0.7656 1.6927h-8.382l-0.5642 3.063h4.5134c-0.6851 1.4508-2.1358 2.4181-4.0701 2.4181-1.8941 0-3.8687-1.3703-3.3045-4.5139 0.5642-2.9824 3.0224-4.393 4.9164-4.393 1.6119 0 2.5791 0.88665 2.9418 1.9345h4.3118c-0.282-2.9018-2.9417-5.3199-6.5282-5.3199-4.4328 0-8.6641 3.2645-9.5104 7.738-0.806 4.3527 1.9746 7.8993 6.5283 7.8993 3.385 0 6.891-1.9748 8.6238-5.4005 0.2821 3.1032 2.6597 5.6826 6.6087 5.6826 3.385 0 6.408-1.8942 8.1-4.5542 0.685 2.6197 2.942 4.6348 6.408 4.6348 3.223 0 6.085-1.6927 7.817-4.1511l-0.725 3.869h5.279c5.682 0 8.382-3.4257 9.148-7.4962 0.604 0.60454 1.571 0.96726 2.74 1.2897 0.806 0.24182 2.257 0.60457 2.055 1.6927-0.161 1.0479-1.209 1.4912-2.095 1.4912-1.209 0-2.176-0.6448-2.982-1.4106-0.605 1.2897-1.088 1.7733-1.975 2.7003 1.29 0.9672 2.821 1.9748 4.594 1.9748 1.491 0 3.103-0.403 4.433-1.4106 1.33-1.0075 1.934-2.4987 2.176-3.9899 0.363-2.2972-1.048-3.3451-3.022-3.9496zm-36.39 1.5718c-0.362 1.9345-2.135 4.4332-4.876 4.4332-2.579 0-3.7475-2.136-3.3042-4.4735 0.4032-2.136 2.2572-4.393 4.9162-4.393 2.781-0.0403 3.627 2.4584 3.264 4.4332zm14.548 0.12091c-0.363 1.9345-2.136 4.4332-4.876 4.4332-2.579 0-3.748-2.136-3.305-4.4735 0.403-2.136 2.257-4.3929 4.917-4.3929 2.78-0.0403 3.627 2.4584 3.264 4.4332zm12.089 0c-0.403 2.136-1.571 4.1511-4.432 4.1511h-1.653l1.532-8.3023h1.652c1.692 0 2.337 0.72544 2.66 1.3703 0.443 0.84635 0.403 1.9345 0.241 2.7809z"></path>
      <path d="m149.46 4.1922c0-2.0556 1.653-3.6679 3.668-3.6679 2.055 0 3.667 1.6123 3.667 3.6679 0 2.0153-1.612 3.6679-3.667 3.6679-2.015 0-3.668-1.6526-3.668-3.6679zm0.605 0c0 1.6929 1.37 3.0633 3.063 3.0633s3.063-1.3704 3.063-3.0633c0-1.7332-1.37-3.0633-3.063-3.0633s-3.063 1.3301-3.063 3.0633zm2.982 0.44338h-0.685v1.6526h-0.645v-4.1919h1.733c0.806 0 1.411 0.48368 1.411 1.2898 0 0.92704-0.846 1.2495-1.088 1.2495l1.128 1.6929h-0.766l-1.088-1.6929zm-0.685-2.0153v1.451h1.088c0.484 0 0.766-0.28214 0.766-0.72551 0-0.48368-0.282-0.76582-0.766-0.76582h-1.088v0.04031z"></path>
    </svg>
  );
};

export default StadiumGoodsIcon;
