import React from 'react';

import './CirclesAnimation.css';

const CirclesAnimation = () => {
  return (
    <ul className="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  );
};

export default CirclesAnimation;
