import React from 'react';

const Coimbra = () => {
  return (
    <>
      <h2>Coimbra Is The Best!</h2>
      <div>LAYOUT</div>
    </>
  );
};

export default Coimbra;
