import React from 'react';

const ReebokIcon = () => {
  return (
    <svg viewBox="0 0 360 100.8">
      <path
        d="M224.87 42.61c41.29-16.53 67-26 135.13-42.61-37 2.44-118.83 8.06-175.23 19.07l40.1 23.54M96.47 97.38h54c10.69-16.19 21.33-24.09 33.72-32.87-9.38-3.62-18.8-7.12-28.38-10.51-34.46 15.21-53.63 35.63-59.3 43.38M107.14 38.46C59 55.67 32.23 74.35 14.16 97.38h59.62c5.81-8.08 27.93-31.4 69.5-47.61-11.46-3.88-31.38-10.1-36.14-11.31M131.85 2.22H0c132.93 24 197 54.91 300 98.58-8.81-6.58-150.77-90.2-168.13-98.58"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default ReebokIcon;
